import React from 'react'
import styled from 'styled-components'
import { v } from '../utils/v-size'

const Container = styled.div`
  margin-left: ${v(20,140)};
  margin-right: ${v(20,140)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - ${v(32,280)});
`
const Content = styled.div`
  p + h3 {
    margin-top: 42px;
  }
  padding: 1rem 0;
  a {
    text-decoration: underline;
  }
  max-width: 800px;
`

const ContentWrapper = props => {
  return (
    <Container>
      <Content>{props.children}</Content>
    </Container>
  )
}

export default ContentWrapper
